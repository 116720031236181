import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Firelogin from "./components/Firebase/Login";
import {
  default as Fire,
  default as Register,
} from "./components/Firebase/Register";
import Gpt from "./views/Gpt";
import Login from "./views/Login";

const App = () => {
  return (
    <Routes>
      {/* 테스트용 */}
      <Route path={"/fire"} element={<Fire />} />
      <Route path={"/login"} element={<Firelogin />} />

      {/* 회원가입용 */}
      <Route path={"/register"} element={<Register />} />

      <Route path={"/"} element={<Login />} />
      <Route path={"/Gpt"} element={<Gpt />} />
    </Routes>
  );
};

export default App;
