import { useState } from "react";
import "../assets/chat-container.css";
import { CONFIG } from "../utils/config";
import { Server } from "../utils/server";
import {
  RiLoopLeftLine,
  RiSendPlaneFill,
  RiFileDownloadLine,
} from "react-icons/ri";

function ChatContainer({ threadID }) {
  const [messages, setMessages] = useState([
    {
      text: "무엇이든 물어보세요! 무엇을 도와드릴까요?",
      sender: "ChatGPT",
    },
  ]);
  const [userMessage, setUserMessage] = useState("");
  const [isCreatingBotMessage, setIsCreatingBotMessage] = useState(false);

  const handleSend = async (message) => {
    if (message === "") {
      return;
    }
    const newMessage = {
      text: message,
      sender: "user",
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);
    setIsCreatingBotMessage(true);
    await Server.sendMessage(threadID, message, async (data) => {
      const apiResponse = await data.json();
      const result = [];
      result.push(...newMessages);
      result.push({
        text: apiResponse.response.message,
        sender: "ChatGPT",
      });
      if (
        apiResponse.response.citedFileIds &&
        apiResponse.response.citedFileIds.length > 0
      ) {
        for (let i = 0; i < apiResponse.response.citedFileIds.length; i++) {
          const citedFileId = apiResponse.response.citedFileIds[i];
          result.push({
            text: "여기를 클릭해 다운로드 해주세요.",
            downloadLink: `${CONFIG.host}download?fileID=${citedFileId}`,
            sender: "download",
          });
        }
      }
      setMessages(result);
      setIsCreatingBotMessage(false);
    });
  };

  return (
    <div className="chat-container">
      <ul className="chat-box">
        {messages.map((message, i) => {
          if (message.sender === "user") {
            return (
              <li key={i} className="chat-user-message">
                {message.text}
              </li>
            );
          } else if (message.sender === "ChatGPT") {
            return (
              <li key={i} className="chat-bot-message">
                {message.text}
              </li>
            );
          } else if (message.sender === "download") {
            return (
              <li
                key={i}
                className="chat-download-message"
                onClick={() => {
                  window.open(message.downloadLink);
                }}
              >
                <RiFileDownloadLine className="download-icon" />
                {message.text}
              </li>
            );
          }
          return null;
        })}
        {isCreatingBotMessage && (
          <div className="is-typing">
            <RiLoopLeftLine className="spin" />
            조금만 기다리세요. YESBot이 분석중입니다.
          </div>
        )}
      </ul>
      <div className="input-container">
        <div className="input-box">
          <input
            className="chat-input"
            disabled={isCreatingBotMessage}
            placeholder="최대한 질문을 자세하게 적어주세요"
            value={userMessage}
            onChange={(event) => setUserMessage(event.currentTarget.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSend(userMessage);
                setUserMessage("");
              }
            }}
          />
          <button
            className="send-button"
            disabled={isCreatingBotMessage}
            onClick={() => {
              handleSend(userMessage);
              setUserMessage("");
            }}
          >
            <RiSendPlaneFill />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatContainer;
