import { CONFIG } from "./config";

export const Server = {
  async sendMessage(threadID, chatMessages, onSuccess) {
    await fetch(
      CONFIG.host + "chat?chat=" + chatMessages + "&threadID=" + threadID,
      {
        method: "GET",
      }
    ).then(onSuccess);
  },

  async getThreadID(onSuccess) {
    await fetch(CONFIG.host + "thread", {
      method: "GET",
    }).then(onSuccess);
  },
};
